import { FC, useMemo } from 'react';
import ActionPreview from '../../ActionPreview';
import { ActionPreviewBaseProps } from '../ActionBaseProps';
import { useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import { useWarnOutboundLink } from '../../../../hooks/useWarnOutboundLink';
import HtmlPreview from '../../../shared/placeholder/HtmlPreview';
import { RichText } from '../../../../models/RichText';
import { convertToRichText } from '../../../../utils/RichTextUtils';

const TextInputPreview: FC<ActionPreviewBaseProps<string | RichText>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewQuestion, previewDescription } = data;
  const { placeholders } = useFormRendererInfo();
  const externalLinkWarner = useWarnOutboundLink();

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  const responseText = convertToRichText(response ?? '')?.value ?? '';

  if (answerOnly) {
    return <>{responseText || '-'}</>;
  }

  return (
    <>
      <ActionPreview
        data-cy="text-input-preview"
        question={title}
        description={previewDescription}
        answer={
          <HtmlPreview placeholders={placeholders} warnOutboundLinks>
            {responseText}
          </HtmlPreview>
        }
      />
      {externalLinkWarner.modal}
    </>
  );
};

export default TextInputPreview;
